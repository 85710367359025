/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'reactstrap';
import { loginUserAdmin } from '../../../api/login';
import Storage from '../../Storage';

export function LoginAdmin() {
  const history = useHistory();

  const handleLogin = async response => {
    const nameAdm = document.querySelector('#nameAdm').value;
    if (!nameAdm) {
      alert('Digite o nome de um cliente');
      return;
    }

    try {
      const data = await loginUserAdmin({
        token: response.credential,
        nameAdm,
      });

      const {
        data: {
          token,
          merchantId,
          managerProfile,
          login,
          merchantConfigs,
          actions,
          user,
          chatToken,
          stores,
          groups,
          ...rest
        },
      } = data;

      Storage.clear();
      if (rest.tomtomJWT) {
        Storage.setItem('tomtomJWT', rest.tomtomJWT);
      }
      Storage.setItem('user', { ...user, storeGroups: groups, groups });
      Storage.setItem(
        'chatToken',
        chatToken || '4a877225-46af-4001-9e54-a97d214b6f06'
      );
      Storage.setItem('blocked', false);
      Storage.setItem('isMaster', true);
      Storage.setItem('token', token);
      Storage.setItem('nameAdm', nameAdm);
      Storage.setItem('profileId', 99);
      Storage.setItem('merchantId', merchantId);
      Storage.setItem('managerProfile', managerProfile);
      Storage.setItem('actions', actions);
      Storage.setItem('login', login);

      history.push('/');

      try {
        Sentry.configureScope(scope => {
          scope.setTag('merchant', nameAdm);
          scope.setUser({ username: `[${nameAdm}] - ${login}` });
        });
      } catch (error) {
        console.log('e: ', error);
      }
    } catch (err) {
      console.log('err', err);
      alert(err?.response?.data || 'Ocorreu um erro');
    }
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        '341122023693-7640dgd79jlv1qsv1j0f6jpvata9qgrc.apps.googleusercontent.com',
      callback: handleLogin,
    });
    google.accounts.id.renderButton(document.querySelector('#button-google'), {
      theme: 'filled_black',
      size: 'large',
      shape: 'pill',
    });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#2b2363',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <img
        style={{
          position: 'absolute',
          top: 80,
          pointerEvents: 'none',
          zIndex: 0,
        }}
        width="500"
        src="/img/logo.png"
        alt=""
      />
      <Input
        style={{
          width: 300,
          marginBottom: '1rem',
          zIndex: 1,
          border: '2px solid black',
        }}
        placeholder="Loja"
        id="nameAdm"
      />
      <div id="button-google" />
    </div>
  );
}
