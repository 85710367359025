import styled from 'styled-components';
import { Button, Col, Input } from 'reactstrap';
import { isMobile } from 'react-device-detect';

export const ButtonLike = styled(Button)`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 5px 10px;

  color: ${props => (props.videoLike ? 'rgb(49, 144, 240)' : '#000')};

  :focus {
    box-shadow: inherit;
  }

  :active {
    color: inherit !important;
    background-color: inherit !important;
    border-color: inherit !important;
    box-shadow: inherit !important;
  }

  :hover {
    color: rgb(49, 144, 240);
    background: none;
  }
`;

export const ButtonClose = styled(Button)`
  border: none;
  font-size: 18px;

  :hover {
    background: none;
  }
`;

export const ButtonInput = styled(Button)`
  width: 68px;
  border-radius: 0px 4px 4px 0px !important;
`;

export const InputText = styled(Input)`
  border-radius: 4px 0px 0px 4px !important;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: larger;
`;

export const ColClose = styled(Col)`
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: ${isMobile ? 'flex-start' : 'flex-end'};
  margin-top: ${isMobile ? '10px' : '0px'};
`;
