import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getManagerMenu } from './api/menu';
import { loginUser } from './api/login';
import Storage from './views/Storage';

const InternalContext = createContext({});

export const stylesToast = {
  autoClose: 3000,
  isLoading: false,
  draggable: true,
  closeButton: true,
  closeOnClick: true,
};

export const StateProvider = ({ children }) => {
  const [run, setRun] = useState(false);
  const [menus, setMenus] = useState([]);
  const [flatMenus, setFlatMenus] = useState([]);

  const history = useHistory();

  const logout = () => {
    setMenus([]);
    setFlatMenus([]);
  };

  const loginUserManager = async ({
    nameAdm,
    userLogin,
    password,
    tokenCaptcha,
  }) => {
    const toastId = toast.loading('Realizando login', {
      position: 'top-center',
    });
    return loginUser(nameAdm, userLogin, password, tokenCaptcha)
      .then(data => {
        if (data.status === 200) {
          const {
            data: {
              token,
              merchantId,
              managerProfile,
              login,
              merchantConfigs,
              actions,
              user,
              chatToken,
              stores,
              groups,
              ...rest
            },
          } = data;

          Sentry.configureScope(scope => {
            scope.setTag('merchant', nameAdm);
            scope.setUser({ username: `[${nameAdm}] - ${userLogin}` });
          });

          const blocked = merchantConfigs.find(
            ({ key, value }) => key === 'BLOCKED' && value === '1'
          );

          if (blocked) {
            Storage.clear();
            Storage.setItem('blocked', true);
            history.push('error');
            return;
          }
          Storage.clear();
          if (rest.tomtomJWT) {
            Storage.setItem('tomtomJWT', rest.tomtomJWT);
          }
          Storage.setItem('user', { ...user, storeGroups: groups, groups });
          Storage.setItem(
            'chatToken',
            chatToken || '4a877225-46af-4001-9e54-a97d214b6f06'
          );
          Storage.setItem('blocked', false);
          Storage.setItem('token', token);
          Storage.setItem('nameAdm', nameAdm);
          Storage.setItem(
            'profileId',
            managerProfile.menus.find(
              ({ managerMenuId: id }) => id === 4 || id === 49
            )
              ? 1
              : 99
          );
          Storage.setItem('merchantId', merchantId);
          Storage.setItem('managerProfile', managerProfile);
          Storage.setItem('actions', actions);
          Storage.setItem('login', login);

          toast.update(toastId, {
            render: `Bem vindo, ${user.name}!`,
            type: 'success',
            ...stylesToast,
          });

          if (managerProfile.type === 'CallCenter') {
            history.push('callcenter');
          } else {
            history.push('/');
          }
        } else {
          Sentry.captureException(data);
          toast.error('Usuário ou senha incorretos!', {
            position: 'top-center',
            ...stylesToast,
          });
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        toast.update(toastId, {
          render: 'Usuário ou senha incorretos!',
          type: 'error',
          ...stylesToast,
        });
      });
  };

  const load = useCallback(async () => {
    const user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))?.value
      : null;
    if (!user) {
      setFlatMenus([]);
      setMenus([]);
      return;
    }
    const menusData = await getManagerMenu();
    if ((user.id === 170 && user.login === 'master') || user.id === 5953) {
      menusData.push({
        id: 999,
        name: 'Master',
        url: '/',
        icon: 'fa fa-unlock',
        children: [
          {
            icon: 'fa fa-store',
            name: 'Notificar PDV',
            parentManagerMenuId: 999,
            url: '/notify-pdv-messages',
          },
          {
            icon: 'fa fa-store',
            name: 'KDS',
            parentManagerMenuId: 999,
            url: '/kds',
          },
          {
            icon: 'fa fa-store',
            name: 'Configurar Loja',
            parentManagerMenuId: 999,
            url: '/store/configs',
          },
          {
            name: 'Configurar Pagamentos Digitais',
            parentManagerMenuId: 999,
            url: '/store/paymentintegration',
            icon: 'fa fa-handshake',
          },
          {
            name: 'Configuração Integrações',
            parentManagerMenuId: 999,
            url: '/store/integrations',
            icon: 'fa fa-handshake',
          },
          {
            name: 'Configuração de computadores',
            parentManagerMenuId: 999,
            url: '/store/devices',
            icon: 'fa fa-laptop',
          },
          {
            name: 'Dre Novo',
            parentManagerMenuId: 999,
            url: '/reports/new/dre',
            icon: 'fa fa-industry',
          },
          {
            icon: 'fa fa-file',
            name: 'Série de Nota',
            parentManagerMenuId: 999,
            url: '/fiscal/serie',
          },
          {
            icon: 'fa fa-file',
            name: 'Relatorio por Loja Teste',
            parentManagerMenuId: 999,
            url: '/opreports/store/new',
          },
          {
            icon: 'fa fa-file',
            name: 'Relatorio por pagamento Teste',
            parentManagerMenuId: 999,
            url: '/opreports/ordes/payments/new',
          },
          {
            icon: 'fa fa-desktop',
            name: 'Configurações Teste', // temporário
            parentManagerMenuId: 999,
            url: '/salespoint/ui',
          },
        ],
        tabs: [],
      });
    }

    menusData.forEach(menu => {
      flatMenus.push(menu);
      menu?.tabs?.forEach(tab => flatMenus.push(tab));
      if (menu.children) {
        menu.children.forEach(child => {
          child?.tabs?.forEach(tab => flatMenus.push(tab));
          flatMenus.push(child);
        });
      }
    });
    setMenus(menusData);
    setFlatMenus(flatMenus);
  }, []);

  useEffect(() => {
    load();
  }, []);

  return (
    <InternalContext.Provider
      value={{
        menus,
        flatMenus,
        run,
        setRun,
        load,
        logout,
        loginUserManager,
      }}
    >
      {children}
    </InternalContext.Provider>
  );
};

export const useInternalContext = () => useContext(InternalContext);
