/* eslint-disable no-param-reassign */
import React from 'react';
import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import _ from 'lodash';
import { css } from 'glamor';
import { toast } from 'react-toastify';
import Storage from '../Storage';

export * from './danfe';

export const stringify = object => (object ? object._text : null);

export const normalizeOptionsToIds = options => {
  if (!options) return [];
  const optionsFiltered = options.filter(option => option?.value);
  if (optionsFiltered.length > 0)
    return optionsFiltered.map(option => option.value).join(',');
  return [];
};

export const normalizeOptionsToStr = options => {
  if (!options) return [];
  const optionsFiltered = options.filter(option => option?.value);
  if (optionsFiltered.length > 0)
    return optionsFiltered.map(option => `"${option.value}"`).join(',');
  return [];
};

export const getParamFromUrl = (hash, param) => {
  if (!hash || hash.indexOf('?') === -1) {
    return null;
  }
  const params = hash.substring(hash.indexOf('?') + 1).split('&');
  for (let i = 0; i < params.length; i += 1) {
    const splited = params[i].split('=');
    if (splited[0] === param) return splited[1];
  }
  return null;
};

export const formatDateReport = date =>
  date && date.format ? date.format('DD/MM/YYYY') : '-';

export const normalizeOptionsToLabel = (options, defaultValue) => {
  if (!options) return defaultValue;
  if (options.length > 0) return options.map(option => option.label).join(',');
  return defaultValue;
};
export const normalizeName = (name, withSpace = false) => {
  const escaped = `${escape(
    name
      .toString()
      .replace('"', '')
      .replace('"', '')
      .replace('"', '')
      .replace('"', '')
      .trim()
  )}`;
  try {
    return !name || name.toString().trim().length === 0
      ? withSpace
        ? ' '
        : ''
      : decodeURIComponent(escaped);
  } catch (e) {
    return name;
  }
};

export const unitTypeToText = value => {
  const units = { 1: 'Kg', 2: 'L', 3: 'Un' };
  return units[parseInt(value, 10)] || null;
};

export const historyTypeToText = value => {
  const types = {
    1: 'Item adicionado',
    2: 'Item removido',
    3: 'Item recebido',
    4: 'Pedido Pago',
    5: 'Pedido Loja Criado',
    6: 'Pedido Cancelado',
    7: 'Alteração de status',
    8: 'Item transferido',
    9: 'Alteração de pedido',
    10: 'Pedido em fechamneto',
    11: 'Fechamento Cancelado',
    12: 'Alteração de pagamento',
    13: 'Pedido CallCenter Criado',
  };
  return types[parseInt(value, 10)] || null;
};

export const toBlr = (value, showEmpty = true, split = 1, precision = 2) => {
  if (!showEmpty && (!value || value === 0)) return 'R$ 0,00';

  return currencyFormatter.format(value / split, {
    symbol: 'R$ ',
    decimal: ',',
    thousand: '.',
    precision,
  });
};

export const calcTotalCart = (
  cartProducts,
  deliveryFee = 0,
  format = true,
  deliveryType = 1
) => {
  const total = cartProducts.reduce(
    (previusValue, currentValue, index, items) => {
      const sum = calcProductTotalValue({
        cartProduct: items[index],
        sumTotal: true,
        format: false,
        deliveryType,
      });
      return previusValue + sum;
    },
    0
  );
  if (format) return toBlr(total + deliveryFee);

  return total + deliveryFee;
};

export const calcProductTotalValue = ({
  cartProduct,
  sumTotal = false,
  format = true,
  divide = 1,
  deliveryType = 1,
}) => {
  if (!cartProduct) return;
  const { cartVariations: variations } = cartProduct;
  const { price, priceDelivery } = cartProduct;
  let totalPrice = deliveryType === 1 ? priceDelivery || price : price;

  if (variations) {
    variations.forEach(variation => {
      const multi = variation.type === 2 ? 1 / variation.items.length : 1;
      variation.items.forEach(item => {
        totalPrice += item.price * multi;
      });
    });
  }
  if (format === undefined || format === true)
    return toBlr(
      totalPrice * (sumTotal ? cartProduct.total : 1),
      false,
      divide
    );

  return totalPrice * (sumTotal ? cartProduct.quantity : 1);
};

export const toCents = number =>
  Math.round(
    100 *
      parseFloat(
        typeof number === 'string'
          ? number.replace('R$', '').replace(' ', '').replace(/[$,]/g, '')
          : number
      )
  );

export const widthColumn = column => {
  if (!column.groupedHeaders) {
    return column && column.width ? `${column.width}px` : '200px';
  }
  const totalWidth = column.groupedHeaders.reduce(
    (previusValue, currentValue, index, items) => {
      const item = items[index];
      return previusValue + item.width;
    },
    0
  );
  return `${totalWidth}px`;
};

export const dataAlign = column =>
  column && column.type.toLowerCase() === 'currency' ? 'right' : null;

const formatNfeKey = nfekey =>
  `${nfekey}`.split('').reduceRight((current, after) => {
    const key = after + current;
    if (key.replace(/\s/g, '').length % 4 === 0) {
      return ` ${key}`;
    }
    return key;
  });

export const formatCell = (
  cell,
  column,
  defaultValue = '',
  row,
  isCsv = false
) => {
  if (column.field === 'invoiceKey' && isCsv) {
    return formatNfeKey(cell);
  }
  if (!column || !column.type || column.type.toLowerCase() === 'string')
    return cell || '';

  if (!cell) return defaultValue;

  if (column.type.toLowerCase() === 'groupedheader') {
    if (isCsv) {
      return column.label;
    }
    return (
      <table style={{ width: '100%' }}>
        <tbody>
          {column.groupedHeaders.map((header, index) => (
            <td
              style={{
                border: 'none',
                width: header.width || 150,
                borderLeft: index === 0 ? 'none' : '1px solid black',
              }}
            >
              {row && row[header.field] ? row[header.field] : ''}
            </td>
          ))}
        </tbody>
      </table>
    );
  }
  if (column.type.toLowerCase() === 'currency') return toBlr(cell / 100 || 0.0);
  if (column.type.toLowerCase() === 'second') {
    const floatCell = parseFloat(cell);
    const minutes = Math.floor(floatCell / 60);
    if (cell <= 0) {
      return '-';
    }
    const hour = Math.floor(minutes / 60);
    if (cell > 0 && minutes < 1) {
      return `${cell}s`;
    }
    if (minutes < 60) {
      return `${minutes}m`;
    }
    return `${hour}h`;
  }
  if (column.type.toLowerCase() === 'date') {
    if (!cell) return defaultValue || '-';

    const cellMoment = moment(cell).utc();
    return cellMoment.isValid() ? cellMoment.format('DD/MM/YYYY') : cell || '-';
  }
  if (column.type.toLowerCase() === 'datetime') {
    if (!cell) return defaultValue || '-';
    const cellMoment = moment(cell);
    return cellMoment.isValid()
      ? cellMoment.format('DD/MM/YYYY HH:mm')
      : cell || '-';
  }
  if (column.type.toLowerCase() === 'time')
    return cell ? moment(cell).format('HH:mm') : '-';
  if (column.type.toLowerCase() === 'percent')
    return cell ? `% ${(cell * 100)?.toFixed(2)}` : '-';
  if (column.type.toLowerCase() === 'time_diff') {
    const date = moment(cell);
    return `${date.format('HH:mm')} ${moment().diff(date, 'minutes')} min`;
  }
  if (column.type.toLowerCase() === 'day_of_week')
    return cell
      ? moment(cell, 'YYYY-MM-DD').locale('pt-BR').format('ddd')
      : '-';
  if (column.type.toLowerCase() === 'number' && !cell) {
    return 0;
  }
  if (column.type.toLowerCase() === 'number' && typeof cell !== 'string') {
    return Number(cell.toFixed(4)).toLocaleString('pt-BR');
  }
  if (column.type.toLowerCase() === 'number' && typeof cell === 'string') {
    const isDouble = cell.indexOf(',') !== -1 || cell.indexOf('.') !== -1;
    const splitedDecimalCase = cell.split(',');
    const splitedDecimalCaseWithPoint = cell.split('.');
    if (isDouble && splitedDecimalCase[1]) {
      if (splitedDecimalCase[1].length > 4) {
        return Number(
          parseFloat(cell.replace(',', '.')).toFixed(4)
        ).toLocaleString('pt-BR');
      }
      return Number(parseFloat(cell.replace(',', '.'))).toLocaleString('pt-BR');
    }
    if (isDouble && splitedDecimalCaseWithPoint[1]) {
      if (splitedDecimalCaseWithPoint[1].length > 4) {
        return Number(parseFloat(cell).toFixed(4)).toLocaleString('pt-BR');
      }
      return Number(parseFloat(cell)).toLocaleString('pt-BR');
    }
    return cell;
  }
  return cell;
};

export const findTotalizer = (totalizers, column, format = true) => {
  if (!totalizers) return '';

  const totalizer = totalizers.find(({ field }) => field === column.field);
  if (!totalizer) return '-';

  if (!totalizer.type) {
    if (format) return toBlr(totalizer.total / 100 || 0.0);
    return totalizer.total || 0.0;
  }
  return totalizer.total || '-';
};

export const toSymbol = (number, prefix = 'R$', sufix = '', divide = 100) => {
  let toFormat = number;
  if (typeof number === 'string') {
    toFormat = toCents(number);
  }
  if (!toFormat) return '';
  const notCents = (toFormat / divide).toFixed(2);
  if (!notCents) return toFormat;
  if (notCents < 10.0) {
    return `${prefix} 0${notCents} ${sufix}`;
  }
  return `${prefix} ${notCents} ${sufix}`;
};

export const DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZ';
// 2018-11-06T00:01:01.000+0000
// 2018-11-06T00:00:00.000-02

export const formatDateToBrTime = ({ date, endOfDay }) => {
  const clonedEndDate = moment(date).local();
  clonedEndDate.set('hour', 0);
  clonedEndDate.set('minute', 0);
  clonedEndDate.set('second', 0);
  if (endOfDay) {
    clonedEndDate.set('hour', 23);
    clonedEndDate.set('minute', 59);
    clonedEndDate.set('second', 59);
  }
  return clonedEndDate.format('DD/MM/YYYY HH:mm');
};

export const getSelectValue = select =>
  !select || select.toString().trim() === '' ? null : select.value;

export const getSelectLabel = select =>
  !select || select.toString().trim() === '' ? null : select.label;

export const roundEven = value => Math.round(value * 100) / 100;

export const createUrlFromArray = urlArray =>
  urlArray.filter(url => url).join('&');

export const colorLineBySubTotal = row => {
  if (!row) return;
  const { type } = row;
  if (!type) return null;

  if (type.toUpperCase() === 'SUB_TOTAL')
    return { fontWeight: 'bold', backgroundColor: '#D3D3D3', color: 'black' };
  if (type.toUpperCase() === 'SUB_TOTAL_1')
    return {
      backgroundColor: 'rgba(211, 211, 211,.4)',
      color: 'black',
    };
  if (type.toUpperCase() === 'TOTAL')
    return {
      color: 'black',
      backgroundColor: '#A9A9A9',
      fontWeight: 'bold',
    };
  return null;
};

export const addItemToUrl = (name, value) => {
  if (
    value === null ||
    value === undefined ||
    value === '[]' ||
    value === '[null]' ||
    value === '[undefined]' ||
    value === ''
  )
    return null;
  if (typeof value === 'array' && value.length === 0) return null;
  if (typeof value === 'array') return `${name}=[${value}]`;
  if (name === 'limit' || name === 'offset') {
    if (value !== undefined && value !== null) return `${name}=${value}`;
    return null;
  }
  return `${name}=${value}`;
};

export const formatDateToUtcString = ({
  date,
  sumDay,
  startDay = true,
  format = DATE_FORMAT,
}) => {
  const clonedEndDate = moment(date).local();
  if (startDay) {
    clonedEndDate.set('hour', 0);
    clonedEndDate.set('minute', 0);
    clonedEndDate.set('second', 0);
  }
  if (sumDay) {
    clonedEndDate.add(1, 'days');
    return clonedEndDate.format(format);
  }
  return clonedEndDate.format(format);
};

export const hashCode = str => {
  if (!str) {
    return null;
  }

  if (str.toLowerCase() === 'app') {
    str = 'paba';
  }
  if (str.toLowerCase() === 'mesa/comanda') {
    str = 'pdv';
  }
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
};

export const isBlocked = () => Storage.getItem('blocked') || false;

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const numberToEnglish = number => {
  if (!number) return 'zero';

  let string = number.toString();
  let units;
  let tens;
  let scales;
  let start;
  let end;
  let chunks;
  let chunksLen;
  let chunk;
  let ints;
  let i;
  let word;
  let words;
  const and = 'and';

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, '');

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return 'zero';
  }

  /* Array of units as words */
  units = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];

  /* Array of tens as words */
  tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  /* Array of scales as words */
  scales = [
    '',
    'thousand',
    'million',
    'billion',
    'trillion',
    'quadrillion',
    'quintillion',
    'sextillion',
    'septillion',
    'octillion',
    'nonillion',
    'decillion',
    'undecillion',
    'duodecillion',
    'tredecillion',
    'quatttuor-decillion',
    'quindecillion',
    'sexdecillion',
    'septen-decillion',
    'octodecillion',
    'novemdecillion',
    'vigintillion',
    'centillion',
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return '';
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split('').reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(`${word} hundred`);
      }
    }
  }
  return words.reverse().join('');
  // const backObject = [];
  // for(let i=0; i < number; i++){
  //   backObject.push(words.reverse().join(""))
  // }
  // return backObject.join("");
};

export const intToRGB = i => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
};

export const xml2json = srcDOM => {
  const children = [...srcDOM.children];

  if (!children.length) {
    const value = srcDOM.innerHTML;
    if (
      srcDOM.tagName.toString().toLowerCase().indexOf('cnpj') !== -1 ||
      srcDOM.tagName.toString().toLowerCase().indexOf('chnfe') !== -1
    ) {
      return value;
    }

    if (!isNaN(value) && value.toString().indexOf('.') != -1) {
      return parseFloat(value);
    }
    if (!isNaN(value)) {
      return parseInt(value);
    }
    return value;
  }

  const jsonResult = {};

  for (const child of children) {
    const childIsArray =
      children.filter(eachChild => eachChild.nodeName === child.nodeName)
        .length > 1;

    if (childIsArray) {
      if (jsonResult[child.nodeName] === undefined) {
        jsonResult[child.nodeName] = [xml2json(child)];
      } else {
        jsonResult[child.nodeName].push(xml2json(child));
      }
    } else {
      jsonResult[child.nodeName] = xml2json(child);
    }
  }

  return jsonResult;
};

export const insertAt = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];

const generateSubItems = cartVariations => {
  const subItems = [];
  if (!cartVariations) return subItems;

  cartVariations.forEach(variation => {
    variation.items.forEach(value => {
      const subItem = {
        quantity: 1,
        discount: 0,
        type: variation.type,
        product: {
          id: value.id,
          name: value.name,
          price: value.price,
        },
      };
      subItems.push(subItem);
    });
  });
  return subItems;
};
export const createOrder = ({
  id = null,
  discount = 0,
  cartProducts,
  deliveryType = 1,
  delivery,
  address,
  customer,
  storeId,
  payments,
  sourceId = 110,
  scheduleDate,
  schedule = 0,
  observation,
  deliverySum = true,
  discountObservation,
  promocodeId = null,
}) => {
  delete customer.mainAddress;
  delete customer.address;
  delete customer.errorMessage;
  delete customer.lastOrders;
  const output = {
    id,
    customer,
    storeId,
    deliveryFee: deliverySum ? delivery.data.deliveryFee : 0,
    address,
    discountId: -1,
    discount: toCents(discount),
    items: cartProducts.map((product, index) => ({
      sequence: index + 1,
      quantity: product.quantity,
      observation: product.observation || '',
      type: 0,
      product: {
        id: product.id,
        name: product.name,
        price:
          deliveryType === 1
            ? product.priceDelivery || product.price
            : product.price,
      },
      subItems: generateSubItems(product.cartVariations),
    })),

    orderType: deliveryType,
    scheduled: schedule,
    createdDate: schedule ? moment(scheduleDate).utc() : null,
    observation,
    discountObservation,
    sourceId,
    promocodeId,
    paymentStatus: 0,
    productTotal: calcTotalCart(cartProducts, 0, false, deliveryType),
    total:
      calcTotalCart(
        cartProducts,
        deliverySum ? delivery.data.deliveryFee : 0,
        false,
        deliveryType
      ) - toCents(discount),
    payments: payments.map(payment => ({
      value: toCents(payment.value),
      paymentMethod: {
        id: payment.id,
        name: payment.name,
        key: payment.methodKey,
      },
    })),
  };

  return output;
};

export const formateWhatsappDate = date => {
  if (moment().format('DDMMYY') === date.format('DDMMYY')) {
    return date.format('HH:mm');
  }
  const diffDays = moment().local().diff(date.local(), 'days');
  if (diffDays <= 1) {
    return `Ontem ${date.format('HH:mm')}`;
  }
  if (diffDays < 7) {
    return date.locale('pt-BR').format('dddd HH:mm');
  }
  return date.format('DD/MM/YYYY');
};

export const removeBugNumberOfVersion = version => {
  const versionSplitted = version.split('.');
  versionSplitted.pop();
  const versionFormatted = versionSplitted.join('.');
  return versionFormatted;
};

export const distinctBy = (key, array) => {
  const keys = array.map(value => value[key]);
  return array.filter((value, index) => keys.indexOf(value[key]) === index);
};

export const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const removeDuplicateOfArrayById = array =>
  array.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

export const getPreviewDropzone = file => {
  const type = file.type.split('/')[0];
  const obj = {
    image: URL.createObjectURL(file),
    video: '/img/icons/video.svg',
    application: '/img/icons/file.png',
  };
  return obj[type];
};

export const getUniqueArray = (array, prop1, prop2) =>
  Object.values(
    array.reduce((a, c) => {
      a[`${c[prop1]}|${c[prop2]}`] = c;
      return a;
    }, {})
  );

export const downloadFile = ({ file, type, name, extension }) => {
  const blob = new Blob(extension === 'csv' ? [`\uFEFF${file}`] : [file], {
    type: `${type};charset=utf-8;`,
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${name}.${extension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const sortArrayByName = array =>
  array.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

export const merchantHasStoresGroups = () => {
  if (!JSON.parse(localStorage.getItem('user'))?.value?.groups) {
    return false;
  }
  return JSON.parse(localStorage.getItem('user')).value.groups.length > 1;
};

export const toastWarn = message =>
  toast.warn(message, {
    className: css({
      color: 'black !important',
    }),
  });

export const colorTextDecorationLine = '#D3D3D3';

export const optionAllStoresIsSelected = stores =>
  stores[0]?.value === 'ALL' || stores.value === 'ALL';

export const formatAndFilterStoresByType = ({ stores, type = 'NORMAL' }) =>
  stores
    .filter(store => store.type === type)
    .map(store => ({
      value: store.id,
      label: store.name,
    }));

export const getAllVirtualStores = stores =>
  stores
    .filter(store => store.type === 'VIRTUAL')
    .map(store => ({
      value: store.id,
      label: store.name,
    }));

// Function to convert the JSON(Array of objects) to CSV.
export const jsonToCsv = json => {
  json = Object.values(json);
  let csv = '';
  const keys = (json[0] && Object.keys(json[0])) || [];
  csv += `${keys.join(',')}\n`;
  for (var line of json) {
    csv += `${keys.map(key => line[key]).join(',')}\n`;
  }
  return csv;
};

function pattern(str) {
  return _.deburr(str)
    .toLowerCase()
    .replace('unidaes', 'unidades')
    .replace(/[aeiou]/gi, '')
    .replace('-', '');
}
export const createHash = (name, price, parentCategoryName) => {
  const string = `${pattern(name)}${price}${parentCategoryName}`
    .toUpperCase()
    .trim()
    .replace(' ', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace(' ', '');
  let hash = 0;

  if (string.length === 0) return hash;

  for (let i = 0; i < string.length; i += 1) {
    const char = string.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  return `${hash}`;
};
export const getUniqueByKey = (arr, key = 'id') => [
  ...new Map(arr.map(item => [item[key], item])).values(),
];

export const getUniqueById = (arr, key = 'id') => [
  ...new Map(arr.map(item => [item[key], item])).values(),
];

export const createStoreEncodedName = name =>
  name?.replace(/[^a-z0-9]|\s+|\r?\n|\r/gim, '-');

export const generateUUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const taxValueNF = total =>
  total
    ? total.ICMSTot.vNF -
      (parseFloat(total.ICMSTot.vProd, 10) +
        parseFloat(total.ICMSTot.vDesc, 10) * -1) -
      parseFloat(total.ICMSTot.vFrete, 10)
    : 0;

// 0.008 retorna 0.00
// 0.153543 retorna 0.15
export const cutDecimalPrecision = number => Math.floor(number * 100) / 100;

export const safeClone = obj => {
  if (window.structuredClone) {
    return window.structuredClone(obj);
  }
  if (!obj) return null;
  return JSON.parse(JSON.stringify(obj));
};

export const generateErrorMessage = (
  error,
  defaultMessage = 'Ops! Ocorreu um erro inesperado!'
) => error?.response?.data?.userMessage || defaultMessage;

export const removeAccents = str => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const typeToPayment = type => {
  const payment = {
    0: 'Dinheiro',
    1: 'Cartão de Débito',
    2: 'Cartão de Crédito',
    3: 'Ticket',
  };
  return payment[type];
};

export const channelToLabel = channel => {
  const labels = {
    102: 'ifood',
    103: 'deliverydireto',
    106: 'uber',
    110: 'phone',
    100: 'whatsapp',
    111: 'cashdesk',
    1111: 'qrcode',
    112: 'ecommerce',
    114: 'table',
    115: 'app',
    116: 'rappi',
    118: 'zedelivery',
    117: 'anotaai',
    119: '99food',
    120: 'americanas',
    121: 'goomer',
    122: 'goomer',
    123: 'gototem',
    125: 'auto',
  };

  return labels[channel];
};
