import axios from "axios";

export const generateDanfe = xml => {
  const bodyFormData = new FormData();
  bodyFormData.set("arquivoXml", xml);
  return axios.post(
    "https://www.webdanfe.com.br/danfe/GeraDanfe.php",
    bodyFormData
  );
};
