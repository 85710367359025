import React from 'react';
import loading from './loading.svg';

const TableLoader = props => (
  <div
    style={{
      justifyContent: 'center',
      alignContent: 'center',
      width: '100%',
      paddingLeft: '0%',
      textAlign: 'center',
      height: '100%',
      ...props.style,
    }}
  >
    <img
      alt=" "
      className="loadingAnimation"
      style={{
        height: '20%',
        width: '20%',
        fill: 'black',
      }}
      src={loading}
    />
    <br />
    {props.messages && (
      <span
        style={{
          marginTop: 10,
          float: 'left',
          width: '100%',
          color: 'black',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {props.messages.map(message => (
          <div key={message}>
            {message}
            <br />
          </div>
        ))}
      </span>
    )}
  </div>
);

export default TableLoader;
