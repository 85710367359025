import React, { useState } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import * as Sentry from '@sentry/react';

import './style.css';

/**
 * @returns {React.JSX.Element}
 */
export function FallbackError() {
  const reloadPage = () => {
    window.location.reload();
  };
  const [feedbackIsSent, setFeedbackIsSent] = useState(false);
  const [userFeedback, setUserFeedback] = useState({
    name: '',
    email: '',
    comments: '',
  });

  const submit = () => {
    const eventId = Sentry.lastEventId();

    const feedback = {
      event_id: eventId,
      ...userFeedback,
    };

    Sentry.captureUserFeedback(feedback);
    setFeedbackIsSent(true);
  };

  return (
    <div>
      <div className="fallback-container">
        <img
          className="fallback-logo"
          src="/img/opdv-roxo.png"
          alt="Logo Opdv"
        />
        <h1 className="fallback-title">Ocorreu um erro inesperado 😥</h1>
        <h2 className="fallback-message">Nossa equipe já foi notificada.</h2>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}
        >
          <Button className="fallback-button" onClick={reloadPage}>
            Recarregar página
          </Button>
        </div>
        <hr style={{ width: '90%' }} />
        <div style={{ width: '100%' }}>
          <h2 className="fallback-message">
            Você gostaria de acrescentar mais informações para ajudar na solução
            do problema?
          </h2>
          {!feedbackIsSent && (
            <>
              <FormGroup className="fallback-form-group">
                <Label>Nome</Label>
                <Input
                  value={userFeedback.name}
                  onChange={({ target: { value } }) =>
                    setUserFeedback({
                      ...userFeedback,
                      name: value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="fallback-form-group">
                <Label>Email</Label>
                <Input
                  value={userFeedback.email}
                  onChange={({ target: { value } }) =>
                    setUserFeedback({
                      ...userFeedback,
                      email: value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="fallback-form-group">
                <Label>O que aconteceu?</Label>
                <Input
                  type="textarea"
                  value={userFeedback.comments}
                  onChange={({ target: { value } }) =>
                    setUserFeedback({
                      ...userFeedback,
                      comments: value,
                    })
                  }
                  placeholder='Eu cliquei em "X" e então cliquei "Confirmar"'
                />
              </FormGroup>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1rem',
                }}
              >
                <Button
                  className="fallback-button-outline"
                  onClick={submit}
                  outline
                >
                  Enviar
                </Button>
              </div>
            </>
          )}
          {feedbackIsSent && (
            <div
              style={{
                textAlign: 'center',
                fontSize: 18,
                fontWeight: 600,
                color: '#444444',
              }}
            >
              Muito obrigado pelo seu feedback! 💜
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
