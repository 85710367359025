import { useEffect, useRef } from 'react';

/**
 * @param {Function} callback
 * @param {number} delay
 * @returns {object}
 */
export const useInterval = (callback, delay) => {
  const intervalRef = useRef();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(
        () => callbackRef.current(),
        delay
      );
      return () => window.clearInterval(intervalRef.current);
    }
    return () => {};
  }, [delay]);

  return intervalRef;
};
