import { generateRequest } from './index';

export const getManagerMenu = async () => {
  const request = await generateRequest();
  const resp = await request.get(`manager/menu`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const getMenuActions = async () => {
  const request = generateRequest();
  const resp = await request.get(`manager/menu/actions`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const getManagerMenuFull = async () => {
  const request = await generateRequest();
  const resp = await request.get(`manager/menu/full`);
  if (resp.status === 200) {
    return resp.data;
  }
  return [];
};

export const saveLog = async url => {
  const request = await generateRequest();
  const resp = await request.post(`logs`, { url });
  if (resp.status === 200) {
    return resp.data;
  }
  return {};
};
