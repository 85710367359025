import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import YouTube from 'react-youtube';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  ButtonGroup,
} from 'reactstrap';
import { useInterval } from '../../hooks/useInterval';
import { createOrUpdateUserTraining, getUserTraining } from '../../api/user';
import {
  ButtonClose,
  ButtonLike,
  ButtonInput,
  ColClose,
  InputText,
  Text,
} from './styles';

/**
 * @returns {React.JSX.Element}
 */
export function ModalVideoTutorial({ isOpen, setIsOpen, title, videoId }) {
  const { pathname } = useLocation();

  const [counter, setCounter] = useState(0);
  const [videoPlaybackRate, setVideoPlaybackRate] = useState(1);
  const [videoDurationInSeconds, setVideoDurationInSeconds] = useState(0);

  const [videoIsPaused, setVideoIsPaused] = useState(true);
  const [videoComeToEnd, setVideoComeToEnd] = useState(false);
  const [videoWasWatched, setVideoWasWatched] = useState(false);

  const [hasUserComment, setHasUserComment] = useState(false);
  const [userFeedback, setUserFeedback] = useState({
    videoLike: '',
    videoComment: '',
  });

  const intervalRef = useInterval(
    () => {
      if (counter <= videoDurationInSeconds) {
        setCounter(prevTime => prevTime + 1);
      }
    },
    videoIsPaused ? null : parseInt(1000 / videoPlaybackRate, 10)
  );

  const registerUserTraining = async () => {
    try {
      await createOrUpdateUserTraining({
        url: pathname,
        videoWasWatched: videoWasWatched ? 'YES' : 'NO',
        videoHasComeToEnd: videoWasWatched || videoComeToEnd ? 'YES' : 'NO',
        videoWasAcessedDate: new Date().toISOString(),
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log('erro ao registrar treino', err);
    }
  };

  const registerUserFeedbackLike = async videoLike => {
    setUserFeedback(prev => ({ ...prev, videoLike }));
    try {
      await createOrUpdateUserTraining({
        url: pathname,
        videoLike,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log('erro ao registrar feedback', err);
    }
  };

  const registerUserFeedbackComment = async () => {
    const { videoComment } = userFeedback;

    if (!videoComment) {
      toast.warn('Adicione um comentário!', { position: 'top-center' });
      return;
    }

    try {
      await createOrUpdateUserTraining({
        url: pathname,
        videoComment,
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log('erro ao registrar feedback', err);
    } finally {
      setHasUserComment(true);
      toast.success('Agradecemos pelo feedback!', { position: 'top-center' });
    }
  };

  const closeModal = () => {
    setCounter(0);
    setIsOpen(false);
    setVideoIsPaused(true);
    setVideoComeToEnd(false);
    setVideoWasWatched(false);
    setVideoDurationInSeconds(0);
  };

  useEffect(() => {
    if (isOpen && (videoComeToEnd || videoWasWatched)) {
      registerUserTraining();
    }
  }, [videoComeToEnd, videoWasWatched]);

  useEffect(() => {
    if (isOpen && counter + 10 > videoDurationInSeconds && !videoWasWatched) {
      setVideoWasWatched(true);
    }
  }, [counter]);

  useEffect(() => {
    async function fetchUserTraining() {
      const { videoLike, videoComment } = await getUserTraining(pathname);
      setUserFeedback({ videoLike, videoComment });
      setHasUserComment(videoComment || false);
    }
    fetchUserTraining();
  }, [pathname]);

  return (
    <Modal isOpen={isOpen} size="lg" className="modal-primary">
      <ModalHeader
        close={
          <ButtonClose color="primary" onClick={closeModal}>
            <i className="fa fa-close" />
          </ButtonClose>
        }
        style={{ alignItems: 'center' }}
      >
        {title}
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <YouTube
          videoId={videoId}
          opts={{ height: 500, width: '100%', playerVars: { autoplay: 0 } }}
          onPlaybackRateChange={({ data }) => setVideoPlaybackRate(data)}
          onReady={event => {
            setVideoDurationInSeconds(event.target.getDuration());
            setVideoPlaybackRate(event.target.getPlaybackRate());
          }}
          onPlay={() => setVideoIsPaused(false)}
          onPause={() => setVideoIsPaused(true)}
          onEnd={() => {
            setVideoComeToEnd(true);
            setVideoIsPaused(true);
            window.clearInterval(intervalRef.current);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Container>
          <Row>
            <Col lg={6}>
              <Row style={{ marginTop: '5px' }}>
                <Text>Este vídeo foi útil?</Text>
                <ButtonGroup style={{ padding: '0px 10px' }}>
                  <ButtonLike
                    color="primary"
                    onClick={() => registerUserFeedbackLike('YES')}
                    videoLike={userFeedback.videoLike === 'YES'}
                  >
                    {userFeedback.videoLike === 'YES' ? (
                      <i className="fa-solid fa-thumbs-up" />
                    ) : (
                      <i className="fa-regular fa-thumbs-up" />
                    )}
                  </ButtonLike>
                  <ButtonLike
                    color="primary"
                    onClick={() => registerUserFeedbackLike('NO')}
                    videoLike={userFeedback.videoLike === 'NO'}
                  >
                    {userFeedback.videoLike === 'NO' ? (
                      <i className="fa-solid fa-thumbs-down" />
                    ) : (
                      <i className="fa-regular fa-thumbs-down" />
                    )}
                  </ButtonLike>
                </ButtonGroup>
              </Row>
              {!hasUserComment && (
                <Row style={{ marginTop: '10px' }}>
                  <InputGroup>
                    <InputText
                      color="primary"
                      placeholder="Deixe um comentário..."
                      onChange={e =>
                        setUserFeedback(prev => ({
                          ...prev,
                          videoComment: e.target.value,
                        }))
                      }
                    />
                    <ButtonInput
                      color="primary"
                      onClick={registerUserFeedbackComment}
                    >
                      Enviar
                    </ButtonInput>
                  </InputGroup>
                </Row>
              )}
            </Col>
            <ColClose lg={6}>
              <Button color="primary" onClick={closeModal}>
                Fechar
              </Button>
            </ColClose>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  );
}
