/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import TableLoader from '../../../components/Loader/TableLoader';
import { loginAnotaai } from '../../../api/login';
import Storage from '../../Storage';
import { stylesToast } from '../../../Context';

const LoginAnotaAi = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const doLogin = async () => {
    // Split URL by '#' to isolate query params part
    const [_, queryParams] = window.location.href.split('?');
    const params = new URLSearchParams(queryParams);

    // Retrieve jwt and redirectUrl
    const jwt = params.get('jwt');
    const redirectUrl = params.get('redirectUrl');
    if (!jwt) {
      setError(true);
      setIsLoading(false);
      return;
    }
    Storage.clear();
    try {
      const data = await loginAnotaai({ jwt });
      console.log('# data: ', data);
      if (!data) {
        setError(true);
        return;
      }
      const { token, userId, merchantId, managerProfile, actions, isAnotaAi } =
        data;
      Storage.setItem('user', {
        id: userId,
        userId,
        merchantId,
        storeGroups: [],
        groups: [],
      });
      Storage.setItem('token', token);
      Storage.setItem('isAnotaAi', isAnotaAi);
      Storage.setItem('nameAdm', 'AnotaAi-User');
      Storage.setItem('merchantId', merchantId);
      Storage.setItem('managerProfile', managerProfile);
      Storage.setItem('actions', actions);
      Storage.setItem(
        'profileId',
        managerProfile.menus.find(
          ({ managerMenuId: id }) => id === 4 || id === 49
        )
          ? 1
          : 99
      );
      toast.success({
        render: `Bem vindo!`,
        type: 'success',
        ...stylesToast,
      });
      const url = redirectUrl.replace('https://anotaai.opdv.com.br/#', '');
      console.log('URL : ', url);
      history.push(url);
    } catch (e) {
      console.error(e);
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    doLogin();
  }, []);

  return (
    <div className="app flex-row align-items-center">
      <Container>
        {isLoading && <TableLoader messages={['Validando credencial']} />}
        {error && (
          <div className="error-anota">
            <div
              style={{
                display: 'flex',
                direction: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src="/img/anota_erro.png"
                alt="OPDV + Anotaai imagem de erro"
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <span className="ops">Ops!</span>
                <span className="ops-description">
                  Ocorreu um erro ao validar sua credencial.
                </span>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default LoginAnotaAi;
