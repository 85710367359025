import store from 'store';

class Storage {
  static setItem(key, value, exp) {
    if (key) {
      store.set(key, {
        value,
        exp: exp || null,
        time: new Date().getTime(),
      });

      return store.get(key);
    }
    return null;
  }

  static getItem(key) {
    const info = store.get(key);

    if (!info) return null;

    return info.value;
  }

  static clear() {
    store.clearAll();
  }
}

export default Storage;
